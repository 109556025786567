import { CampaignDeliverableMetricsFragment } from "~/__generated__/graphql"
import { Subsection } from "~/campaigns/subsection"
import { SubsectionBody } from "~/campaigns/subsection-body"
import { SubsectionTitle } from "~/campaigns/subsection-title"
import { formatPercentage } from "~/common/percentage-formattingt"
import { calculatePercent } from "~/ui/percent"

export const SocialPostsSidebarMetrics: React.FC<{
  campaignDeliverable: CampaignDeliverableMetricsFragment
}> = ({ campaignDeliverable }) => {
  const metrics = {
    impressionsMetric: campaignDeliverable.impressionsMetric ?? 0,
    totalClicksMetric: campaignDeliverable.totalClicksMetric ?? 0,
    notes: campaignDeliverable.metricNotes ?? "",
  }

  const clickThroughRate = calculatePercent(
    metrics.totalClicksMetric,
    metrics.impressionsMetric
  )

  return (
    <>
      <Subsection>
        <SubsectionTitle>Impressions</SubsectionTitle>
        <SubsectionBody>
          {metrics.impressionsMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Clicks</SubsectionTitle>
        <SubsectionBody>
          {metrics.totalClicksMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Click Through Rate (CTR)</SubsectionTitle>
        <SubsectionBody>{formatPercentage(clickThroughRate)}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Notes</SubsectionTitle>
        <SubsectionBody>{metrics.notes ? metrics.notes : "–"}</SubsectionBody>
      </Subsection>
    </>
  )
}
