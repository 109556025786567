import { CommandLoading } from "cmdk"
import { Check, ChevronsUpDown } from "lucide-react"
import React, { useState } from "react"
import { cn } from "~/common/cn"
import { Button } from "~/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/ui/command"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"

type OptionType = {
  label: string
  value: string
}

export const Select = React.forwardRef(
  (
    {
      text,
      options,
      value,
      onChange,
      onOpen,
      isLoading = false,
      disabled = false,
      typeAhead = false,
      className = "",
      onQueryChange,
    }: {
      text: React.ReactNode
      options: OptionType[]
      value?: string
      onChange: (value: string) => void
      onOpen?: () => void
      isLoading?: boolean
      disabled?: boolean
      typeAhead?: boolean
      onQueryChange?: (query: string) => void
      className?: string
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [open, setOpen] = useState(false)

    const onOpenChange = () => {
      // if open is false, it means we're opening the popover and we want to call the onOpen callback
      if (!open && onOpen) {
        onOpen()
      }
      setOpen(!open)
    }

    return (
      <Popover open={open} onOpenChange={onOpenChange} modal={true}>
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "w-full px-3 font-normal justify-between rounded-lg bg-white",
              className
            )}
            onClick={() => setOpen(!open)}
          >
            <div className="flex gap-1 flex-wrap w-full overflow-x-hidden truncate text-ellipsis">
              <span className="text-placeholder text-sm">{text}</span>
            </div>
            <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-56 p-0 flex flex-col space-y-2">
          <Command>
            {typeAhead && (
              <CommandInput
                placeholder="Search company..."
                className="h-9 border-0 focus:border-transparent focus:ring-0 text-xs-plus"
                onValueChange={onQueryChange}
              />
            )}
            <CommandEmpty className="text-sm p-2">Nothing here...</CommandEmpty>
            {isLoading && options.length === 0 && (
              <CommandLoading>
                <LoadingIndicatorCentered />
              </CommandLoading>
            )}
            <CommandGroup>
              <CommandList>
                {options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={() => {
                      onChange(option.value)
                      setOpen(false)
                    }}
                    keywords={[option.label]}
                    className="flex items-center space-x-3 relative"
                  >
                    <span
                      className={cn(
                        "absolute left-2 flex h-3.5 w-3.5 items-center justify-center",
                        {
                          "opacity-100": value === option.value,
                          "opacity-0": value !== option.value,
                        }
                      )}
                    >
                      <Check className="h-4 w-4" />
                    </span>

                    <span className="pl-3">{option.label}</span>
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    )
  }
)
