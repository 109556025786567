import type { Editor as TiptapEditor } from "@tiptap/core"
import { getMarkRange } from "@tiptap/core"
import { Link } from "@tiptap/extension-link"
import TextStyle from "@tiptap/extension-text-style"
import { Underline } from "@tiptap/extension-underline"
import { Plugin, TextSelection } from "@tiptap/pm/state"
import { EditorContent, Extension, JSONContent, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import React, { forwardRef, useEffect } from "react"
import { FontSize } from "tiptap-extension-font-size"
import { cn } from "~/common/cn"
import { LinkBubbleMenu } from "./bubble-menu/link-bubble-menu"
import SectionTwo, { ToolbarButtonConfig } from "./section-2"

export interface MinimalTiptapProps
  extends React.HTMLAttributes<HTMLDivElement> {
  value: JSONContent | null | undefined
  disabled?: boolean
  contentClass?: string
  onValueChange: (value: JSONContent, html: string) => void
  config?: ToolbarButtonConfig
  size?: "sm" | "default"
  editorRef?: React.MutableRefObject<TiptapEditor | null>
  contentStyle?: React.CSSProperties
  linkStyle?: React.CSSProperties
}

const objectToCSSString = (styles: React.CSSProperties): string => {
  return Object.entries(styles)
    .map(([key, value]) => {
      // Convert camelCase to kebab-case
      const property = key.replace(/([A-Z])/g, "-$1").toLowerCase()
      return `${property}: ${value};`
    })
    .join(" ")
}

const ParagraphFontSize = Extension.create({
  name: "paragraphFontSize",

  addGlobalAttributes() {
    return [
      {
        types: ["paragraph"],
        attributes: {
          fontSize: {
            default: "15px",
            parseHTML: (element) => element.style.fontSize,
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {}
              }
              return {
                style: `font-size: ${attributes.fontSize}`,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize: string) =>
        ({ commands }) => {
          return commands.updateAttributes("paragraph", { fontSize })
        },
    }
  },
})

const MinimalTiptapEditor = forwardRef<HTMLDivElement, MinimalTiptapProps>(
  (
    {
      value,
      disabled,
      contentClass,
      onValueChange,
      className,
      size = "default",
      config = {
        bold: true,
        italic: true,
        underline: true,
        strike: true,
        link: true,
        orderedList: true,
        bulletList: true,
      },
      editorRef,
      contentStyle = null,
      linkStyle = null,
      ...props
    },
    ref
  ) => {
    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          paragraph: {
            HTMLAttributes: {
              style: contentStyle ? objectToCSSString(contentStyle) : null,
            },
          },
        }),
        TextStyle,
        FontSize,
        ParagraphFontSize,
        Underline.configure({}),
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {},
        }).extend({
          // https://github.com/ueberdosis/tiptap/issues/2571
          inclusive: false,

          addAttributes() {
            return {
              ...this.parent?.(),
              style: {
                default: linkStyle ? objectToCSSString(linkStyle) : "",
                parseHTML: (element) => {
                  return element.getAttribute("style")
                },
                renderHTML: (attributes) => {
                  if (!attributes.style) {
                    return {}
                  }
                  return { style: attributes.style }
                },
              },
            }
          },

          addProseMirrorPlugins() {
            return [
              new Plugin({
                // mark the link
                props: {
                  handleClick(view, pos) {
                    const { schema, doc, tr } = view.state
                    const range = getMarkRange(
                      doc.resolve(pos),
                      schema.marks.link
                    )

                    if (!range) {
                      return
                    }

                    const { from, to } = range
                    const start = Math.min(from, to)
                    const end = Math.max(from, to)

                    if (pos < start || pos > end) {
                      return
                    }

                    const $start = doc.resolve(start)
                    const $end = doc.resolve(end)
                    const transaction = tr.setSelection(
                      new TextSelection($start, $end)
                    )

                    view.dispatch(transaction)
                  },
                },
              }),
            ]
          },
        }),
      ],
      editorProps: {
        attributes: {
          class:
            "prose mx-auto focus:outline-none max-w-none prose-stone dark:prose-invert",
        },
      },
      onUpdate: (props) => {
        onValueChange(props.editor.getJSON(), props.editor.getHTML())
      },
      content: value,
      editable: !disabled,
    })

    // Add an effect to handle content updates
    useEffect(() => {
      if (editor && value) {
        editor.commands.setContent(value)
      }
    }, [editor, value])

    // This way we can update the editor using setContent from the onChange callbacks in the parent
    // to dynamically update the editor content when we pick something from the dropdown
    if (editor && editorRef) {
      editorRef.current = editor
    }

    return (
      <div
        className={cn(
          "flex h-auto min-h-72 w-full flex-col rounded-md border border-input shadow-sm focus-within:border-primary",
          className
        )}
        {...props}
        ref={ref}
      >
        {editor && (
          <>
            <LinkBubbleMenu editor={editor} />
            <Toolbar editor={editor} config={config} size={size} />
          </>
        )}
        <div
          className="h-full grow"
          onClick={() => editor?.chain().focus().run()}
        >
          <EditorContent
            editor={editor}
            className={cn(
              size === "default" && "p-5",
              size === "sm" && "p-2",
              contentClass,
              {
                "cursor-not-allowed opacity-50 bg-gray-f9": disabled,
              }
            )}
          />
        </div>
      </div>
    )
  }
)

MinimalTiptapEditor.displayName = "MinimalTiptapEditor"

const Toolbar = ({
  editor,
  config,
  size = "default",
}: {
  editor: TiptapEditor
  config: ToolbarButtonConfig
  size: "sm" | "default"
}) => {
  return (
    <div
      className={cn(
        size === "default" && "p-2",
        size === "sm" && "p-1",
        "border-b border-border bg-gray-f9 rounded-t-md"
      )}
    >
      <div className="flex w-full flex-wrap items-center">
        <SectionTwo editor={editor} config={config} size={size} />
      </div>
    </div>
  )
}

export { MinimalTiptapEditor }
