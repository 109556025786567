import { TableHead } from "~/ui/table"

export const SocialPostsColumns = () => (
  <>
    <TableHead className="w-[150px]">Impressions</TableHead>
    <TableHead className="w-[150px]">Clicks</TableHead>
    <TableHead className="w-[150px]" colSpan={2}>
      CTR
    </TableHead>
  </>
)
