import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs"
import React, { useEffect, useState } from "react"
import { useForm, UseFormReturn } from "react-hook-form"
import { useParams, useSearchParams } from "react-router-dom"
import { Params } from "static-path"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CampaignDeliverableStatus,
  CampaignDeliverableTypeFilter,
  CampaignMetricsDeliverableFragment,
  ProductBriefStatus,
  Role,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { toCalendarDate } from "~/common/date-formatting"
import { gqlMatchOptional } from "~/common/gql-match"
import * as paths from "~/common/paths"
import { useDocumentTitle } from "~/common/use-document-title"
import { useGoBack } from "~/common/use-go-back"
import { countNonDefaults } from "~/common/zod-changed-defaults"
import { URLParamsSerializer } from "~/common/zod-search-params"
import { CompanyLogo } from "~/companies/company-logo"
import editIcon from "~/images/icons/edit"
import sendIcon from "~/images/icons/send"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { ActiveFilters } from "~/search/active-filters"
import { NoResults } from "~/search/no-results"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { FilterButton } from "~/ui/filter-button"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Heading } from "~/ui/heading"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Table, TableBody, TableHead, TableHeader, TableRow } from "~/ui/table"
import Text from "~/ui/typography"
import { ExportButton } from "./export-button"
import { GoBack } from "./go-back"
import { BrandedArticlesColumns } from "./metrics/branded-articles/branded-articles-columns"
import { BrandedArticlesDeliverableRow } from "./metrics/branded-articles/branded-articles-deliverable-row"
import { CampaignBrandedArticlesTotals } from "./metrics/branded-articles/campaign-branded-articles-totals"
import { CampaignEventTotals } from "./metrics/events/campaign-event-totals"
import { EventColumns } from "./metrics/events/event-columns"
import { EventsDeliverableRow } from "./metrics/events/events-deliverable-row"
import { CampaignLeadGenerationTotals } from "./metrics/lead-generation/campaign-lead-generation-totals"
import { LeadGenColumns } from "./metrics/lead-generation/lead-gen-columns"
import { LeadGenDeliverableRow } from "./metrics/lead-generation/lead-gen-deliverable-row"
import { CampaignCachedEnrichedStats } from "./metrics/newsletters/campaign-cached-enriched-stats"
import { CampaignNewsletterTotals } from "./metrics/newsletters/campaign-newsletter-totals"
import { NewsletterColumns } from "./metrics/newsletters/newsletter-columns"
import { NewsletterDeliverableRow } from "./metrics/newsletters/newsletter-deliverable-row"
import { CampaignPodcastTotals } from "./metrics/podcasts/campaign-podcast-totals"
import { PodcastColumns } from "./metrics/podcasts/podcast-columns"
import { PodcastDeliverableRow } from "./metrics/podcasts/podcast-deliverable-row"
import { CampaignSocialTotals } from "./metrics/social/campaign-social-totals"
import { SocialPostsColumns } from "./metrics/social/social-posts-columns"
import { SocialPostsDeliverableRow } from "./metrics/social/social-posts-deliverable-row"
import { NotifyUsersAboutMetricsModal } from "./notify-users-about-metrics-modal"
import { SendMetricsModal } from "./send-metrics-modal"

const PRODUCTS_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query SearchDeliverableProducts(
    $companyId: ID!
    $statuses: [ProductBriefStatus!]
    $search: String
    $first: Int = 10
  ) {
    productBriefs(
      filter: { companyId: $companyId, statuses: $statuses, search: $search }
      first: $first
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

gql(/* GraphQL */ `
  fragment CampaignMetricsDeliverable on CampaignDeliverable {
    id
    deliverableName
    publishDateCanonical
    metricNotes
    recipientsMetric
    opensMetric
    totalClicksMetric
    totalRealClicksMetric
    deliverableType
    metricsPending

    leadsSuppliedMetric
    leadsToDateMetric
    dateOfCompletion

    registrantsMetric
    liveAttendeesMetric

    downloads7DaysPostLaunchMetric
    downloads30DaysPostLaunchMetric
    impressions7DaysPostLaunchMetric
    impressions30DaysPostLaunchMetric
    impressionsMetric

    timeOnSiteMetric
    pageviewsMetric

    sailthruCampaignId
    ctaUrl

    productBrief {
      id
      name
      company {
        slug
      }
    }
    creatorBrand {
      id
      name
    }
    campaign {
      id
      campaignName
    }
  }
`)

const query = gql(/* GraphQL */ `
  query CampaignMetricsQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Campaign {
        id
        campaignName

        hasNewsletterAdsMetrics
        hasLeadGenerationMetrics
        hasEventsMetrics
        hasPodcastsMetrics
        hasBrandedArticlesMetrics
        hasSocialMetrics

        company {
          id
          name
        }

        creatorBrands(first: 100) {
          edges {
            node {
              id
              name
            }
          }
        }

        client {
          id
          firstName
          lastName
          email
          role
          title
          status
          companies {
            edges {
              node {
                name
              }
            }
          }
          ...UserAvatar
        }
      }
    }
  }
`)

const campaignDeliverablesQuery = gql(/* GraphQL */ `
  query CampaignMetricsDeliverablesQuery(
    $campaignId: ID!
    $productBriefIds: [ID!]
    $creatorBrandIds: [ID!]
    $statuses: [CampaignDeliverableStatus!]
    $deliverableType: CampaignDeliverableTypeFilter
  ) {
    campaignDeliverables(
      filter: {
        campaignId: $campaignId
        productBriefIds: $productBriefIds
        creatorBrandIds: $creatorBrandIds
        statuses: $statuses
        deliverableType: $deliverableType
      }
    ) {
      edges {
        node {
          ...CampaignMetricsDeliverable
        }
      }
    }
  }
`)

const SAVE_CAMPAIGN_METRICS = gql(/* GraphQL */ `
  mutation CampaignMetricsSave($input: CampaignMetricsSaveInput!) {
    campaignMetricsSave(input: $input) {
      campaignDeliverables {
        id
        opensMetric
        recipientsMetric
        totalClicksMetric
        totalRealClicksMetric
        metricNotes
        metricsPending

        leadsSuppliedMetric
        leadsToDateMetric
        dateOfCompletion

        registrantsMetric
        liveAttendeesMetric

        downloads7DaysPostLaunchMetric
        downloads30DaysPostLaunchMetric
        impressions7DaysPostLaunchMetric
        impressions30DaysPostLaunchMetric
        impressionsMetric

        timeOnSiteMetric
        pageviewsMetric
      }
    }
  }
`)

const formSchema = z.object({
  products: z.array(z.string()),
  creatorBrands: z.array(z.string()),
})

const formDefaults: z.infer<typeof formSchema> = {
  products: [],
  creatorBrands: [],
}

const serializer = new URLParamsSerializer(formSchema, formDefaults)

const deliverableSchema = z.object({
  id: z.string(),
  recipientsMetric: z.string().min(0),
  opensMetric: z.string().min(0),
  totalClicksMetric: z.string().min(0),
  totalRealClicksMetric: z.string().min(0),
  metricNotes: z.string(),
  leadsSuppliedMetric: z.string().min(0),
  leadsToDateMetric: z.string().min(0),
  dateOfCompletion: z.date().optional(),
  registrantsMetric: z.string().min(0),
  liveAttendeesMetric: z.string().min(0),
  downloads7DaysPostLaunchMetric: z.string().min(0),
  downloads30DaysPostLaunchMetric: z.string().min(0),
  impressions7DaysPostLaunchMetric: z.string().min(0),
  impressions30DaysPostLaunchMetric: z.string().min(0),
  impressionsMetric: z.string().min(0),
  timeOnSiteMetric: z
    .string()
    .min(0)
    .refine(
      (value) => value.match(/^[0-9]{2}:[0-5][0-9]$/),
      "format must be: mm:ss"
    ),
  pageviewsMetric: z.string().min(0),
  metricsPending: z.boolean(),
})

export const metricsFormSchema = z.object({
  deliverables: z.array(deliverableSchema),
})

export const CampaignMetricsScreen = () => {
  const [sendMetricsModalIsOpen, setSendMetricsModalIsOpen] = useState(false)
  const [
    notifyUsersAboutMetricsModalIsOpen,
    setNotifyUsersAboutMetricsModalIsOpen,
  ] = useState(false)
  const [exportError, setExportError] = useState<string | null>(null)
  const [selectedTab, setSelectedTab] = useState<CampaignDeliverableTypeFilter>(
    CampaignDeliverableTypeFilter.NewsletterAds
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const [productSearchQuery, setProductSearchQuery] = useState("")
  const [debouncedProductSearch] = useDebounce(productSearchQuery, 200)
  const { viewer } = useViewer()

  const canEditMetrics =
    viewer.role === Role.WorkweekAdmin || viewer.role === Role.WorkweekTeam
  const canSendMetrics =
    viewer.role === Role.WorkweekAdmin || viewer.role === Role.WorkweekTeam

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      products: [],
      creatorBrands: [],
    },
  })

  const { watch, setValue } = form

  const formData = watch()

  useEffect(() => {
    const newSearchParams = serializer.serialize(formData)
    if (newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [formData, searchParams, setSearchParams])

  const activeFiltersCount = countNonDefaults(
    formSchema,
    formDefaults,
    formData
  )

  const params = useParams() as Params<
    typeof paths.campaignDetailMetricsPath.pattern
  >
  const goBack = useGoBack(paths.campaignsPath({}))
  const result = useQuery(query, {
    variables: {
      id: params.campaignId,
    },
  })

  const campaign = gqlMatchOptional(result.data?.node, "Campaign")

  const campaignDeliverablesResult = useQuery(campaignDeliverablesQuery, {
    variables: {
      campaignId: campaign?.id ?? "",
      productBriefIds: formData.products,
      creatorBrandIds: formData.creatorBrands,
      statuses: [CampaignDeliverableStatus.Published],
      deliverableType: selectedTab,
    },
    onCompleted: (data) => {
      const cds = data.campaignDeliverables.edges.map((edge) => edge.node)

      // https://tanookilabs.slack.com/archives/C06RB9TD6N8/p1727795095679509?thread_ts=1727725905.789819&cid=C06RB9TD6N8
      // eslint-disable-next-line no-console
      console.log(
        cds.map((campaignDeliverable) => ({
          ctaUrl: campaignDeliverable.ctaUrl,
          sailthruCampaignId: campaignDeliverable.sailthruCampaignId,
        }))
      )
    },
  })

  const getInitialTab = (campaign: any): CampaignDeliverableTypeFilter => {
    if (campaign.hasNewsletterAdsMetrics)
      return CampaignDeliverableTypeFilter.NewsletterAds
    if (campaign.hasLeadGenerationMetrics)
      return CampaignDeliverableTypeFilter.LeadGen
    if (campaign.hasEventsMetrics) return CampaignDeliverableTypeFilter.Events
    if (campaign.hasPodcastsMetrics)
      return CampaignDeliverableTypeFilter.Podcasts
    if (campaign.hasBrandedArticlesMetrics)
      return CampaignDeliverableTypeFilter.BrandedArticles
    if (campaign.hasSocialMetrics)
      return CampaignDeliverableTypeFilter.SocialPosts
    return CampaignDeliverableTypeFilter.NewsletterAds // Default fallback
  }

  const campaignDeliverableTypesToString: Record<
    CampaignDeliverableTypeFilter,
    string
  > = {
    [CampaignDeliverableTypeFilter.NewsletterAds]: "newsletters",
    [CampaignDeliverableTypeFilter.LeadGen]: "lead-generation",
    [CampaignDeliverableTypeFilter.Events]: "events",
    [CampaignDeliverableTypeFilter.Podcasts]: "podcasts",
    [CampaignDeliverableTypeFilter.BrandedArticles]: "branded-articles",
    [CampaignDeliverableTypeFilter.SocialPosts]: "social",
  }

  useEffect(() => {
    if (!campaign) return

    const initialTab = getInitialTab(campaign)
    setSelectedTab(initialTab)
  }, [campaign])

  const campaignDeliverables =
    campaignDeliverablesResult.data?.campaignDeliverables.edges ?? []

  useDocumentTitle(campaign?.campaignName ?? null)

  const [
    execProductQuery,
    {
      data: currentProductsData,
      previousData: previousProductsData,
      loading: productsLoading,
    },
  ] = useLazyQuery(PRODUCTS_QUERY_DOCUMENT, {
    variables: {
      search: debouncedProductSearch,
      companyId: campaign?.company.id ?? "",
      statuses: [ProductBriefStatus.Active],
    },
  })

  const productsData = currentProductsData || previousProductsData
  const products =
    productsData?.productBriefs.edges.map((edge) => edge.node) ?? []

  const clearSearch = () => {
    setValue("products", [])
    setValue("creatorBrands", [])
  }

  const [isEditing, setIsEditing] = useState(false)

  const metricsForm = useForm<z.infer<typeof metricsFormSchema>>({
    resolver: zodResolver(metricsFormSchema),
    values: {
      deliverables: campaignDeliverables.map((d) => ({
        id: d.node.id ?? "",
        recipientsMetric: d.node.recipientsMetric?.toString() ?? "0",
        opensMetric: d.node.opensMetric?.toString() ?? "0",
        totalClicksMetric: d.node.totalClicksMetric?.toString() ?? "0",
        totalRealClicksMetric: d.node.totalRealClicksMetric?.toString() ?? "0",
        metricNotes: d.node.metricNotes ?? "",
        leadsSuppliedMetric: d.node.leadsSuppliedMetric?.toString() ?? "0",
        leadsToDateMetric: d.node.leadsToDateMetric?.toString() ?? "0",
        dateOfCompletion: d.node.dateOfCompletion
          ? toCalendarDate(new Date(d.node.dateOfCompletion))
          : undefined,
        registrantsMetric: d.node.registrantsMetric?.toString() ?? "0",
        liveAttendeesMetric: d.node.liveAttendeesMetric?.toString() ?? "0",
        downloads7DaysPostLaunchMetric:
          d.node.downloads7DaysPostLaunchMetric?.toString() ?? "0",
        downloads30DaysPostLaunchMetric:
          d.node.downloads30DaysPostLaunchMetric?.toString() ?? "0",
        impressions7DaysPostLaunchMetric:
          d.node.impressions7DaysPostLaunchMetric?.toString() ?? "0",
        impressions30DaysPostLaunchMetric:
          d.node.impressions30DaysPostLaunchMetric?.toString() ?? "0",
        impressionsMetric: d.node.impressionsMetric?.toString() ?? "0",
        timeOnSiteMetric: d.node.timeOnSiteMetric?.toString() ?? "00:00",
        pageviewsMetric: d.node.pageviewsMetric?.toString() ?? "0",
        metricsPending: d.node.metricsPending ?? true,
      })),
    },
  })

  const [saveCampaignMetrics] = useMutation(SAVE_CAMPAIGN_METRICS)

  const isNewsletter =
    selectedTab === CampaignDeliverableTypeFilter.NewsletterAds
  const isLeadGeneration = selectedTab === CampaignDeliverableTypeFilter.LeadGen
  const isEvents = selectedTab === CampaignDeliverableTypeFilter.Events
  const isPodcasts = selectedTab === CampaignDeliverableTypeFilter.Podcasts
  const isBrandedArticles =
    selectedTab === CampaignDeliverableTypeFilter.BrandedArticles
  const isSocialPosts =
    selectedTab === CampaignDeliverableTypeFilter.SocialPosts

  if (result.error) {
    return <GraphqlError error={result.error} />
  }

  const onMetricsSubmit = async (data: z.infer<typeof metricsFormSchema>) => {
    const isMetricsPendingUpdated = data.deliverables.some(
      (d, i) =>
        d.metricsPending !==
          metricsForm.formState.defaultValues?.deliverables?.[i]
            ?.metricsPending && d.metricsPending === false
    )

    const result = await saveCampaignMetrics({
      variables: {
        input: {
          campaignId: campaign?.id ?? "",
          deliverables: data.deliverables.map((d, i) => ({
            id: d.id,
            metricNotes: d.metricNotes ?? "",
            metricsPending: d.metricsPending,
            ...(isNewsletter && {
              recipientsMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.recipientsMetric !== d.recipientsMetric
                  ? parseInt(d.recipientsMetric ?? "0")
                  : undefined,
              opensMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.opensMetric !== d.opensMetric
                  ? parseInt(d.opensMetric ?? "0")
                  : undefined,
              totalClicksMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.totalClicksMetric !== d.totalClicksMetric
                  ? parseInt(d.totalClicksMetric ?? "0")
                  : undefined,
              totalRealClicksMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.totalRealClicksMetric !== d.totalRealClicksMetric
                  ? parseInt(d.totalRealClicksMetric ?? "0")
                  : undefined,
            }),
            ...(isLeadGeneration && {
              leadsSuppliedMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.leadsSuppliedMetric !== d.leadsSuppliedMetric
                  ? parseInt(d.leadsSuppliedMetric ?? "0")
                  : undefined,
              leadsToDateMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.leadsToDateMetric !== d.leadsToDateMetric
                  ? parseInt(d.leadsToDateMetric ?? "0")
                  : undefined,
              dateOfCompletion: d.dateOfCompletion
                ? toCalendarDate(d.dateOfCompletion).toISOString()
                : undefined,
            }),
            ...(isEvents && {
              registrantsMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.registrantsMetric !== d.registrantsMetric
                  ? parseInt(d.registrantsMetric ?? "0")
                  : undefined,
              liveAttendeesMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.liveAttendeesMetric !== d.liveAttendeesMetric
                  ? parseInt(d.liveAttendeesMetric ?? "0")
                  : undefined,
              opensMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.opensMetric !== d.opensMetric
                  ? parseInt(d.opensMetric ?? "0")
                  : undefined,
              totalClicksMetric: parseInt(d.totalClicksMetric ?? "0"),
            }),
            ...(isPodcasts && {
              downloads7DaysPostLaunchMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.downloads7DaysPostLaunchMetric !==
                d.downloads7DaysPostLaunchMetric
                  ? parseInt(d.downloads7DaysPostLaunchMetric ?? "0")
                  : undefined,
              downloads30DaysPostLaunchMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.downloads30DaysPostLaunchMetric !==
                d.downloads30DaysPostLaunchMetric
                  ? parseInt(d.downloads30DaysPostLaunchMetric ?? "0")
                  : undefined,
              impressions7DaysPostLaunchMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.impressions7DaysPostLaunchMetric !==
                d.impressions7DaysPostLaunchMetric
                  ? parseInt(d.impressions7DaysPostLaunchMetric ?? "0")
                  : undefined,
              impressions30DaysPostLaunchMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.impressions30DaysPostLaunchMetric !==
                d.impressions30DaysPostLaunchMetric
                  ? parseInt(d.impressions30DaysPostLaunchMetric ?? "0")
                  : undefined,
              impressionsMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.impressionsMetric !== d.impressionsMetric
                  ? parseInt(d.impressionsMetric ?? "0")
                  : undefined,
            }),
            ...(isBrandedArticles && {
              timeOnSiteMetric: d.timeOnSiteMetric.toString() ?? "00:00",
              pageviewsMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.pageviewsMetric !== d.pageviewsMetric
                  ? parseInt(d.pageviewsMetric ?? "0")
                  : undefined,
              totalClicksMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.totalClicksMetric !== d.totalClicksMetric
                  ? parseInt(d.totalClicksMetric ?? "0")
                  : undefined,
            }),
            ...(isSocialPosts && {
              impressionsMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.impressionsMetric !== d.impressionsMetric
                  ? parseInt(d.impressionsMetric ?? "0")
                  : undefined,
              totalClicksMetric:
                metricsForm.formState.defaultValues?.deliverables?.[i]
                  ?.totalClicksMetric !== d.totalClicksMetric
                  ? parseInt(d.totalClicksMetric ?? "0")
                  : undefined,
            }),
          })),
        },
      },
    })

    if (!result.errors) {
      setIsEditing(false)

      if (isMetricsPendingUpdated) {
        setNotifyUsersAboutMetricsModalIsOpen(true)
      }
    }
  }

  if (!campaign) {
    return null
  }

  const creatorBrands =
    campaign.creatorBrands?.edges.map((edge) => edge.node) ?? []

  const activeSearch = activeFiltersCount > 0

  const noDeliverablesFoundTitle = () => {
    switch (selectedTab) {
      case CampaignDeliverableTypeFilter.NewsletterAds:
        return "No Newsletter Deliverables found"
      case CampaignDeliverableTypeFilter.LeadGen:
        return "No Lead Generation Deliverables found"
      case CampaignDeliverableTypeFilter.Events:
        return "No Events Deliverables found"
      case CampaignDeliverableTypeFilter.Podcasts:
        return "No Podcast Deliverables found"
      case CampaignDeliverableTypeFilter.BrandedArticles:
        return "No Branded Article Deliverables found"
      default:
        return "No Deliverables found"
    }
  }

  return (
    <>
      <GoBack onClick={goBack} />

      <TablePageLayout padding={false}>
        <div className="flex flex-col flex-1">
          <div className="flex flex-1 flex-col">
            <div className="p-[40px] gap-[40px] items-start overflow-auto">
              <div className="flex flex-col bg-gray-f9 rounded-lg mb-8">
                <div className="p-8 flex items-center gap-4">
                  <CompanyLogo company={campaign.company} size="32" />
                  <Text as="div" variant="title-24">
                    {campaign.campaignName}
                  </Text>
                </div>
              </div>
              <Tabs
                value={campaignDeliverableTypesToString[selectedTab]}
                className="w-full"
              >
                <TabsList className="w-full border-b border-gray-200">
                  {campaign.hasNewsletterAdsMetrics && (
                    <TabsTrigger
                      value="newsletters"
                      className="px-4 py-2 -mb-px border-b-2 border-transparent data-[state=active]:border-black uppercase"
                      onClick={() =>
                        setSelectedTab(
                          CampaignDeliverableTypeFilter.NewsletterAds
                        )
                      }
                    >
                      <Text variant="body-10-bold">Newsletters</Text>
                    </TabsTrigger>
                  )}
                  {campaign.hasLeadGenerationMetrics && (
                    <TabsTrigger
                      value="lead-generation"
                      className="px-4 py-2 -mb-px border-b-2 border-transparent data-[state=active]:border-black uppercase"
                      onClick={() =>
                        setSelectedTab(CampaignDeliverableTypeFilter.LeadGen)
                      }
                    >
                      <Text variant="body-10-bold">Leads</Text>
                    </TabsTrigger>
                  )}
                  {campaign.hasEventsMetrics && (
                    <TabsTrigger
                      value="events"
                      className="px-4 py-2 -mb-px border-b-2 border-transparent data-[state=active]:border-black uppercase"
                      onClick={() =>
                        setSelectedTab(CampaignDeliverableTypeFilter.Events)
                      }
                    >
                      <Text variant="body-10-bold">Events</Text>
                    </TabsTrigger>
                  )}
                  {campaign.hasPodcastsMetrics && (
                    <TabsTrigger
                      value="podcasts"
                      className="px-4 py-2 -mb-px border-b-2 border-transparent data-[state=active]:border-black uppercase"
                      onClick={() =>
                        setSelectedTab(CampaignDeliverableTypeFilter.Podcasts)
                      }
                    >
                      <Text variant="body-10-bold">Podcasts</Text>
                    </TabsTrigger>
                  )}
                  {campaign.hasBrandedArticlesMetrics && (
                    <TabsTrigger
                      value="branded-articles"
                      className="px-4 py-2 -mb-px border-b-2 border-transparent data-[state=active]:border-black uppercase"
                      onClick={() =>
                        setSelectedTab(
                          CampaignDeliverableTypeFilter.BrandedArticles
                        )
                      }
                    >
                      <Text variant="body-10-bold">Branded Articles</Text>
                    </TabsTrigger>
                  )}
                  {campaign.hasSocialMetrics && (
                    <TabsTrigger
                      value="social"
                      className="px-4 py-2 -mb-px border-b-2 border-transparent data-[state=active]:border-black uppercase"
                      onClick={() =>
                        setSelectedTab(
                          CampaignDeliverableTypeFilter.SocialPosts
                        )
                      }
                    >
                      <Text variant="body-10-bold">Social</Text>
                    </TabsTrigger>
                  )}
                </TabsList>
                <div className="flex justify-between items-center my-4">
                  <Form {...form}>
                    <form className="flex items-center gap-2">
                      <FormField
                        control={form.control}
                        name="creatorBrands"
                        render={({ field }) => (
                          <FormItem className="space-y-0">
                            <FormLabel className="sr-only">
                              Filter by Product
                            </FormLabel>
                            <FormControl>
                              <FilterButton
                                typeAhead
                                text="Filter by Brand"
                                options={creatorBrands.map((creatorBrand) => ({
                                  label: creatorBrand.name,
                                  value: creatorBrand.id,
                                }))}
                                {...field}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="products"
                        render={({ field }) => (
                          <FormItem className="space-y-0">
                            <FormLabel className="sr-only">
                              Filter by Product
                            </FormLabel>
                            <FormControl>
                              <FilterButton
                                typeAhead
                                isLoading={productsLoading}
                                onOpen={() => execProductQuery()}
                                text="Filter by Product"
                                onQueryChange={(query) => {
                                  setProductSearchQuery(query)
                                }}
                                options={products.map((product) => ({
                                  label: product.name,
                                  value: product.id,
                                }))}
                                {...field}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </form>
                  </Form>

                  <div className="flex items-center gap-2">
                    {canEditMetrics && (
                      <>
                        <Button
                          variant="cardControl"
                          size="sm"
                          className="flex items-center gap-2 h-8"
                          onClick={() => setIsEditing(!isEditing)}
                        >
                          <Text variant="body-12">
                            {isEditing ? (
                              "Cancel"
                            ) : (
                              <div className="flex items-center gap-2">
                                <img
                                  {...editIcon}
                                  alt=""
                                  className="inline-block align-baseline"
                                />
                                Edit Metrics
                              </div>
                            )}
                          </Text>
                        </Button>
                        {isEditing && (
                          <Button
                            size="sm"
                            className="h-8 mr-1"
                            onClick={metricsForm.handleSubmit(onMetricsSubmit)}
                          >
                            Save All Changes
                          </Button>
                        )}
                      </>
                    )}
                    <div className="flex flex-col items-center">
                      <ExportButton
                        campaignId={campaign.id}
                        productBriefIds={formData.products}
                        creatorBrandIds={formData.creatorBrands}
                        onError={(error) => {
                          setExportError(error)
                        }}
                      />
                      {exportError && (
                        <div className="text-sm text-destructive mt-2">
                          <Text variant="body-12">{exportError}</Text>
                        </div>
                      )}
                    </div>
                    {canSendMetrics && (
                      <div className="flex flex-col items-center">
                        <Button
                          variant="cardControl"
                          size="sm"
                          className="flex items-center gap-2 h-8"
                          onClick={() => setSendMetricsModalIsOpen(true)}
                        >
                          <Text variant="body-12">
                            <div className="flex items-center gap-2">
                              <img
                                {...sendIcon}
                                alt=""
                                className="inline-block align-baseline"
                              />
                              Send
                            </div>
                          </Text>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="my-12">
                  {campaignDeliverablesResult.loading && (
                    <div className="flex justify-center p-4">
                      <LoadingIndicatorCentered />
                    </div>
                  )}
                  {!campaignDeliverablesResult.loading &&
                    !campaignDeliverablesResult.error &&
                    campaignDeliverables.length === 0 && (
                      <NoResults
                        title={noDeliverablesFoundTitle()}
                        description={
                          activeSearch
                            ? "Clear your search results to view all campaigns"
                            : "We couldn't find any deliverables for the selected type"
                        }
                        onClearSearch={activeSearch ? clearSearch : undefined}
                      />
                    )}
                </div>
                {campaign.hasNewsletterAdsMetrics && (
                  <TabsContent value="newsletters" className="pt-4">
                    {campaignDeliverables.length > 0 && (
                      <>
                        <MetricsTable
                          deliverables={campaignDeliverables.map(
                            (cd) => cd.node
                          )}
                          isEditing={isEditing}
                          form={metricsForm}
                          deliverableTypeFilter={
                            CampaignDeliverableTypeFilter.NewsletterAds
                          }
                        />
                        <div className="mt-16">
                          <CampaignNewsletterTotals form={metricsForm} />
                          <CampaignCachedEnrichedStats
                            campaignId={campaign.id}
                          />
                        </div>
                      </>
                    )}
                  </TabsContent>
                )}
                {campaign.hasLeadGenerationMetrics && (
                  <TabsContent value="lead-generation" className="pt-4">
                    {campaignDeliverables.length > 0 && (
                      <>
                        <MetricsTable
                          deliverables={campaignDeliverables.map(
                            (cd) => cd.node
                          )}
                          isEditing={isEditing}
                          form={metricsForm}
                          deliverableTypeFilter={
                            CampaignDeliverableTypeFilter.LeadGen
                          }
                        />
                        <div className="mt-16">
                          <CampaignLeadGenerationTotals form={metricsForm} />
                        </div>
                      </>
                    )}
                  </TabsContent>
                )}
                {campaign.hasEventsMetrics && (
                  <TabsContent value="events" className="pt-4">
                    {campaignDeliverables.length > 0 && (
                      <>
                        <MetricsTable
                          deliverables={campaignDeliverables.map(
                            (cd) => cd.node
                          )}
                          isEditing={isEditing}
                          form={metricsForm}
                          deliverableTypeFilter={
                            CampaignDeliverableTypeFilter.Events
                          }
                        />
                        <div className="mt-16">
                          <CampaignEventTotals form={metricsForm} />
                        </div>
                      </>
                    )}
                  </TabsContent>
                )}
                {campaign.hasPodcastsMetrics && (
                  <TabsContent value="podcasts" className="pt-4">
                    {campaignDeliverables.length > 0 && (
                      <>
                        <MetricsTable
                          deliverables={campaignDeliverables.map(
                            (cd) => cd.node
                          )}
                          isEditing={isEditing}
                          form={metricsForm}
                          deliverableTypeFilter={
                            CampaignDeliverableTypeFilter.Podcasts
                          }
                        />
                        <div className="mt-16">
                          <CampaignPodcastTotals form={metricsForm} />
                        </div>
                      </>
                    )}
                  </TabsContent>
                )}
                {campaign.hasBrandedArticlesMetrics && (
                  <TabsContent value="branded-articles" className="pt-4">
                    {campaignDeliverables.length > 0 && (
                      <>
                        <MetricsTable
                          deliverables={campaignDeliverables.map(
                            (cd) => cd.node
                          )}
                          isEditing={isEditing}
                          form={metricsForm}
                          deliverableTypeFilter={
                            CampaignDeliverableTypeFilter.BrandedArticles
                          }
                        />
                        <div className="mt-16">
                          <CampaignBrandedArticlesTotals form={metricsForm} />
                        </div>
                      </>
                    )}
                  </TabsContent>
                )}
                {campaign.hasSocialMetrics && (
                  <TabsContent value="social" className="pt-4">
                    {campaignDeliverables.length > 0 && (
                      <>
                        <MetricsTable
                          deliverables={campaignDeliverables.map(
                            (cd) => cd.node
                          )}
                          isEditing={isEditing}
                          form={metricsForm}
                          deliverableTypeFilter={
                            CampaignDeliverableTypeFilter.SocialPosts
                          }
                        />
                        <div className="mt-16">
                          <CampaignSocialTotals form={metricsForm} />
                        </div>
                      </>
                    )}
                  </TabsContent>
                )}
              </Tabs>
              {activeFiltersCount > 0 && (
                <div className="bg-gray-50 rounded-lg p-5 py-4 flex items-center gap-6">
                  <Heading
                    title="Filters applied"
                    count={activeFiltersCount}
                    className="mb-0"
                  />

                  {formData?.products?.length > 0 && (
                    <ActiveFilters
                      label="Product"
                      values={formData.products
                        .map(
                          (id) => products.find((p) => p.id === id)?.name ?? ""
                        )
                        .join(", ")}
                      onClear={() => setValue("products", [])}
                    />
                  )}

                  {formData?.creatorBrands?.length > 0 && (
                    <ActiveFilters
                      label="Creator Brand"
                      values={formData.creatorBrands
                        .map(
                          (id) =>
                            creatorBrands.find((cb) => cb.id === id)?.name ?? ""
                        )
                        .join(", ")}
                      onClear={() => setValue("creatorBrands", [])}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <SendMetricsModal
          onClose={() => setSendMetricsModalIsOpen(false)}
          isOpen={sendMetricsModalIsOpen}
          user={campaign.client}
          campaignId={campaign.id}
        />
        <NotifyUsersAboutMetricsModal
          onClose={() => setNotifyUsersAboutMetricsModalIsOpen(false)}
          isOpen={notifyUsersAboutMetricsModalIsOpen}
          user={campaign.client}
          campaignId={campaign.id}
        />
      </TablePageLayout>
    </>
  )
}

const MetricsTable = ({
  deliverables,
  isEditing,
  form,
  deliverableTypeFilter,
}: {
  deliverables: CampaignMetricsDeliverableFragment[]
  isEditing: boolean
  form: UseFormReturn<z.infer<typeof metricsFormSchema>>
  deliverableTypeFilter: CampaignDeliverableTypeFilter
}) => {
  const tableHeaderForDeliverableType = (
    deliverableTypeFilter: CampaignDeliverableTypeFilter
  ) => {
    switch (deliverableTypeFilter) {
      case CampaignDeliverableTypeFilter.LeadGen:
        return <LeadGenColumns />
      case CampaignDeliverableTypeFilter.NewsletterAds:
        return <NewsletterColumns />
      case CampaignDeliverableTypeFilter.Events:
        return <EventColumns />
      case CampaignDeliverableTypeFilter.Podcasts:
        return <PodcastColumns />
      case CampaignDeliverableTypeFilter.BrandedArticles:
        return <BrandedArticlesColumns />
      case CampaignDeliverableTypeFilter.SocialPosts:
        return <SocialPostsColumns />
    }
  }

  const renderDeliverableRow = (props: {
    deliverable: CampaignMetricsDeliverableFragment
    isEditing: boolean
    index: number
  }) => {
    switch (deliverableTypeFilter) {
      case CampaignDeliverableTypeFilter.LeadGen:
        return <LeadGenDeliverableRow {...props} />
      case CampaignDeliverableTypeFilter.NewsletterAds:
        return <NewsletterDeliverableRow {...props} />
      case CampaignDeliverableTypeFilter.Events:
        return <EventsDeliverableRow {...props} />
      case CampaignDeliverableTypeFilter.Podcasts:
        return <PodcastDeliverableRow {...props} />
      case CampaignDeliverableTypeFilter.BrandedArticles:
        return <BrandedArticlesDeliverableRow {...props} />
      case CampaignDeliverableTypeFilter.SocialPosts:
        return <SocialPostsDeliverableRow {...props} />
    }
  }

  return (
    <Form {...form}>
      <form>
        <Table className="border-spacing-y-0" layout="fixed">
          <TableHeader>
            <TableRow>
              <TableHead className="w-[40px]"></TableHead>
              <TableHead className="w-[160px]">Publish Date</TableHead>
              <TableHead className="w-[300px]">Deliverable</TableHead>
              <TableHead>Product</TableHead>
              <TableHead>Brand</TableHead>
              <TableHead className="w-[80px]">
                {isEditing ? "Pending" : null}
              </TableHead>
              {tableHeaderForDeliverableType(deliverableTypeFilter)}
            </TableRow>
          </TableHeader>
          <TableBody>
            {deliverables?.length > 0 &&
              deliverables?.map((deliverable, index) => (
                <React.Fragment key={deliverable.id}>
                  {renderDeliverableRow({
                    deliverable,
                    isEditing,
                    index,
                  })}
                  <tr>
                    <td colSpan={9} className="h-2"></td>
                  </tr>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </form>
    </Form>
  )
}
